import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WhatWeDo from "../components/WhatWeDo"

const GetInvolvedPage = () => (
  <Layout>
    <SEO title="Get Involved" />
  </Layout>
)

export default GetInvolvedPage
